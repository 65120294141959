import React from "react";

import {
  Input as I,
  FormLabel,
  Textarea,
  InputGroup,
  InputRightElement,
  IconButton,
  Text,
  Box,
  HStack,
  NumberInput,
  NumberInputField,
} from "@chakra-ui/react";

import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useField } from "formik";

const Label = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <FormLabel {...props} ref={ref}>
      {children}
    </FormLabel>
  );
});

const Input = React.forwardRef(({ disabled, isReadOnly, ...props }, ref) => {
  return (
    <I
      ref={ref}
      borderBottomColor={"black"}
      readOnly={isReadOnly}
      {...props}
      variant={"flushed"}
      borderBottom={disabled || isReadOnly ? "0px" : "1px"}
    />
  );
});

const getCurrencySymbol = (currency) => {
  switch (currency) {
    case "IDR":
      return "Rp";
    case "PHP":
      return "₱";
  }
};

const getPrecision = (currency) => {
  switch (currency) {
    case "IDR":
      return 0;
    case "PHP":
      return 2;
  }
};

const FormattedNumberInput = React.forwardRef(
  (
    {
      id,
      disabled,
      isReadOnly,
      onChange: passedOnChange,
      value: passedValue,
      currency = "IDR",
      placeholder,
    },
    ref
  ) => {
    const [props, _, helpers] = useField(id);
    const { setValue } = helpers;
    return (
      <HStack alignItems={"center"} flexGrow={1}>
        <Text>{getCurrencySymbol(currency)}</Text>
        <NumberInput
          margin={0}
          flexGrow={1}
          precision={getPrecision(currency)}
          ref={ref}
          borderBottomColor={"black"}
          variant={"flushed"}
          value={passedValue || props.value}
          /**
           * other people can pass only onChange = (e) => {},
           * then we should only pass that.
           *
           * onChange here i only reserved for Formik usecase
           */
          onChange={
            passedOnChange
              ? (val) => {
                  setValue(val);
                }
              : () => {}
          }
          onChangeCapture={passedOnChange || props.onChange}
          isDisabled={disabled}
          isReadOnly={isReadOnly}
        >
          <NumberInputField
            {...props}
            placeholder={placeholder}
          ></NumberInputField>
        </NumberInput>
      </HStack>
    );
  }
);

const TextArea = React.forwardRef((props, ref) => {
  return (
    <Textarea
      border={"2px"}
      p={2}
      borderColor="black"
      variant={"flushed"}
      backgroundColor="transparent"
      {...props}
      ref={ref}
    />
  );
});

const PasswordInput = React.forwardRef((props, ref) => {
  const [show, setShow] = React.useState(false);
  const toggleMode = () => setShow(!show);
  return (
    <InputGroup>
      <Input {...props} type={show ? "text" : "password"} variant="flushed" />
      <InputRightElement>
        <IconButton
          _hover={{
            background: "transparent",
          }}
          _active={{
            background: "transparent",
          }}
          variant="ghost"
          onClick={toggleMode}
          icon={show ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
          aria-label="View Password"
        />
      </InputRightElement>
    </InputGroup>
  );
});

const Error = ({ children }) => {
  return (
    <div className="f5 red mb2">
      <span>{children}</span>
    </div>
  );
};

export { PasswordInput, TextArea, Label, Input, FormattedNumberInput, Error };

import { normalize, toHours } from "duration-fns";
import getVideoId from "get-video-id";
import { _get, getTiktokUrl } from "./request";
import { useQuery } from "react-query";

const MINIMUM_TIP_AMOUNT = 1_000;
const MAXIMUM_TIP_AMOUNT = 10_000_000;

const DISBURSEMENT_RULE = {
  IDR: {
    min: 50_000,
    cut: 5_000,
  },
  PHP: {
    min: 500,
    cut: 50,
  },
};

const getDisbursementRule = (currency) => {
  return DISBURSEMENT_RULE[currency];
};

const MIN_MAX = {
  IDR: {
    min: 1_000,
    max: 10_000_000,
  },

  PHP: {
    min: 10,
    max: 100_000,
  },
};

const VENDOR_CUT = {
  dana: "1.69%",
  gopay: "2%",
  linkaja: "1.69%",
  ovo: "2.74%",
  qris: "0.7%",
  grabpay: "2.29%",
  gcash: "2.64%",
  paymaya: "2%",
  shopeepay: "2.29%",
}


const getMinTip = (currency) => {
  return MIN_MAX[currency]["min"];
};

const getMaxTip = (currency) => {
  return MIN_MAX[currency]["max"];
};

const getLocale = ({ currency }) => {
  switch (currency) {
    case "PHP":
      return "ph";
    default:
      return "id";
  }
};

const formatMoney = (
  value,
  { formatWithSymbol = true, isShort = false, currency = "IDR" } = {}
) => {
  let opts = {
    compactDisplay: "short",
    notation: "standard",
    currency: currency,
    style: "decimal",
    minimumFractionDigits: currency === "IDR" ? 0 : 2,
  };

  if (isShort) {
    opts = { ...opts, notation: "compact" };
  }
  if (formatWithSymbol) {
    opts = { ...opts, style: "currency" };
  }

  const ret = new Intl.NumberFormat(getLocale({ currency }), {
    ...opts,
  }).format(value);
  // delete the whitespace from ret
  return ret.replace(/\s+/g, '');
};

function shortHandNumber(value) {
  let newValue = value;
  const suffixes = ["", "k", "M", "B", "T"];
  let suffixNum = 0;
  while (newValue >= 1000) {
    newValue /= 1000;
    suffixNum++;
  }

  newValue = parseFloat(newValue.toPrecision(3)) / 1;
  newValue += suffixes[suffixNum];
  return newValue;
}

const getTimeToAdd = (rules, data) => {
  const r = [...rules];
  r.sort((a, b) => {
    return b[0] - a[0];
  });
  let { amount } = data;

  amount = parseInt(amount);
  const filteredRules = r.filter(([amount, _]) => amount);
  let denom = filteredRules.map(([amount, _]) => amount);
  let hours = filteredRules.map(([_, hours]) => hours);
  let minutes = filteredRules.map(([_, hours, minutes, seconds]) => minutes);
  let seconds = filteredRules.map(([_, hours, minutes, seconds]) => seconds);
  let change = denom.map(() => 0);

  for (let i = 0; i < denom.length; i++) {
    let c = denom[i];
    while (amount >= c) {
      amount -= c;
      change[i] += 1;
    }
  }

  /**
   * This works because the rules are filtered
   */
  let timeToAdd = {
    hours: 0,
    minutes: 0,
    seconds: 0,
  };
  for (let i = 0; i < change.length; i++) {
    timeToAdd["hours"] += change[i] * hours[i] || 0;
    timeToAdd["minutes"] += change[i] * minutes[i] || 0;
    timeToAdd["seconds"] += change[i] * seconds[i] || 0;
  }

  return getRemainingTimeToDisplay(normalize(timeToAdd));
};

const getRemainingTimeToDisplay = (duration) => {
  const d = {};
  for (const [key, value] of Object.entries(duration)) {
    d[key] = parseInt(value) || 0;
  }

  let { hours = 0, minutes = 0, seconds = 0, ...otherTimes } = normalize(d);
  const hoursToAdd = toHours({ ...otherTimes });
  return { hours: hours + hoursToAdd, minutes, seconds };
};
const _getTiktokUrl = async (shortUrl) => {
  const {json:{data}} = await get(`donations/get_tiktok_full_video?short_url=${shortUrl}`);
  return data.full_url
};



const getTiktokVideoId = async (url = '' ) =>{
  if (url.includes("vt.tiktok.com")) {
    const  data  =await _getTiktokUrl(url);
    url = data || url
  } 
  return getVideoId(url);
}

const getInstagramVideoId = (url) => {
  // Regular expression to match Instagram post URLs
  try {
    const regex = /(?:https?:\/\/)?(?:www\.)?instagram\.com(?:\/reels\/|\/reel\/)([^\/?]+)(?:\S+)?/i;
    const match = url.match(regex);
    if (!match) {
      return null
    }
    if (match[1]) {
      const postId = match[1];
      return postId;
    }
  } catch (e) {
    return null
  }

}


const blobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result.split(',')[1]);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

export {
  formatMoney,
  getTimeToAdd,
  getRemainingTimeToDisplay,
  shortHandNumber,
  MAXIMUM_TIP_AMOUNT,
  MINIMUM_TIP_AMOUNT,
  getMaxTip,
  getMinTip,
  getDisbursementRule,
  VENDOR_CUT,
  getTiktokVideoId,
  getInstagramVideoId,
  blobToBase64 // Add this to the exports
};

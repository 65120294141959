import React from "react";

const Loader = ({ children }) => {
  if (children) {
    return children;
  }
  return <span>Loading...</span>;
};

export default Loader;

import React from "react";
import { Box, Spinner, Flex } from "@chakra-ui/react";
import Link from "../components/Link";

const Button = React.forwardRef(
  (
    {
      href,
      label,
      loading = false,
      onClick,
      type,
      disabled = false,
      block,
      pressed,
      color = "black",
      backgroundColor = "saweria-pink.normal",
      target = "",
      loadingText = "",
      ...props
    },
    ref
  ) => {
    if (href) {
      return (
        <Box
          ref={ref}
          backgroundColor={backgroundColor}
          color={color}
          paddingY={2}
          paddingX={5}
          marginBottom={3}
          disabled={disabled}
          border="1px solid black"
          borderRadius="md"
          display={block ? "block" : "inline"}
          boxShadow={disabled || pressed ? "pressed" : "buttonRaised"}
          transform={pressed ? "translate(0.1rem, 0.1rem)" : ""}
          transition="box-shadow .1s , transform .2s "
          _active={{
            boxShadow: "pressed",
          }}
          _disabled={{
            cursor: "not-allowed",
          }}
          fontSize="lg"
          textDecoration="none"
          verticalAlign="middle"
          {...props}
        >
          <Flex
            h={"100%"}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Link href={href} target={target}>
              {loading ? (
                <>
                  <Spinner></Spinner> {loadingText}
                </>
              ) : (
                label
              )}
            </Link>
          </Flex>
        </Box>
      );
    }
    return (
      <Box
        ref={ref}
        as="button"
        backgroundColor={backgroundColor}
        color={color}
        paddingY={2}
        paddingX={5}
        marginBottom={3}
        disabled={disabled}
        _disabled={{
          cursor: "not-allowed",
        }}
        border="1px solid black"
        borderRadius="md"
        onClick={onClick}
        type={type}
        display={block ? "block" : "inline"}
        boxShadow={disabled || pressed ? "pressed" : "buttonRaised"}
        transform={pressed ? "translate(0.1rem, 0.1rem)" : ""}
        transition="box-shadow .1s , transform .2s "
        fontSize="lg"
        textDecoration="none"
        {...props}
      >
        {loading ? (
          <>
            <Spinner></Spinner> {loadingText}
          </>
        ) : (
          label
        )}
      </Box>
    );
  }
);

export default Button;
